import React, { useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import styled from "styled-components";
import {
  AnswersItem,
  HeadInner,
  Table,
  WrapAnswers,
  WrapHead,
  WrapSpan,
} from "./rotated-style";
const WrapFrom = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  input {
    display: block;
    margin-bottom: 20px;
  }
  button {
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Admin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const handleLogin = () => {
    // Add your authentication logic here (e.g., check if username and password are correct)
    if (username === "admin" && password === "secretKey!") {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      alert("Некорректный логин и пароль");
    }
  };
  // const [savedData, setSavedData] = useState(null);
  // const [responseData, setResponseData] = useState(null);
  // const [loading, setLoading] = useState(true);
  // const fetchData = async () => {
  //   try {
  //     const fetchDataEndpoint =
  //       "https://admin.mame-darom.ru/wp-json/custom/v1/endpoint";
  //
  //     const response = await fetch(fetchDataEndpoint, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //
  //     if (response.ok) {
  //       const data = await response.json();
  //       setSavedData(data);
  //     } else {
  //       console.error("Failed to fetch data:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };
  //
  // useEffect(() => {
  //   // Fetch data when the component mounts
  //   fetchData();
  // }, []);
  const [submitedAnswersFromServer, setSubmitedAnswersFromServer] = useState(
    [],
  );
  const headers = [
    "Укажите Ваш пол",
    "Введите ваш возраст(числом)",
    "Укажите Ваш стаж работы врачом акушером-гинекологом",
    "Входят ли в ваши обязанности вопросы касаемо абортов?",
    "Если Вы занимаетесь вопросами абортов, то укажите как долго?",
    "Проведение абортов для Вас ",
    "Приходится ли вам осуществлять стоматологические услуги?",
    "Стаж работы стоматологом, как долго?",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
    "Временами не могу справиться с желанием навредить кому-либо.", // III.	Тест Басса -Дарки
    "Иногда могу посплетничать о людях, которых не люблю.",
    "Легко раздражаюсь, но легко и успокаиваюсь.",
    "Если меня не попросят по-хорошему, просьбу не выполню.",
    "Не всегда получаю то, что мне положено.",
    "Знаю, что люди говорят обо мне за моей спиной.",
    "Если не одобряю поступки других людей, даю им это почувствовать.",
    "Если случается обмануть кого-либо, испытываю угрызения совести.",
    "Мне кажется, что я не способен ударить человека.",
    "Никогда не раздражаюсь настолько, чтобы разбрасывать вещи.",
    "Всегда снисходителен к чужим недостаткам.",
    "Когда установленное правило не нравится мне, хочется нарушить его.",
    "Другие почти всегда умеют использовать благоприятные обстоятельства.",
    "Меня настораживают люди, которые относятся ко мне более дружелюбно, чем я этого ожидаю.",
    "Часто бываю, не согласен с людьми.",
    "Иногда на ум приходят мысли, которых я стыжусь.",
    "Если кто-нибудь ударит меня, я не отвечу ему тем же.",
    "В раздражении хлопаю дверьми.",
    "Я более раздражителен, чем кажется со стороны.",
    "Если кто-то корчит из себя начальника, я поступаю ему наперекор.",
    "Меня немного огорчает моя судьба.",
    "Думаю, что многие люди не любят меня.",
    "Не могу удержаться от спора, если люди не согласны со мной.",
    "Увиливающие от работы должны испытывать чувство вины.",
    "Кто оскорбляет меня или мою семью, напрашивается на драку.",
    "Я не способен на грубые шутки.",
    "Меня охватывает ярость, когда надо мной насмехаются.",
    "Когда люди строят из себя начальников, я делаю все, чтобы они не зазнавались.",
    "Почти каждую неделю вижу кого-нибудь из тех, кто мне не нравится.",
    "Довольно многие завидуют мне.",
    "Требую, чтобы люди уважали мои права.",
    "Меня огорчает, что я мало делаю для своих родителей.",
    "Люди, которые постоянно изводят вас, стоят того, чтобы их щелкнули по носу.",
    "От злости иногда бываю мрачным.",
    "Если ко мне относятся хуже, чем я того заслуживаю, я не огорчаюсь.",
    "Если кто-то пытается вывести меня из себя, я не обращаю на него внимания.",
    "Хотя я и не показываю этого, иногда меня гложет зависть.",
    "Иногда мне кажется, что надо мной смеются.",
    "Даже если злюсь, не прибегаю к сильным выражениям.",
    "Хочется, чтобы мои грехи были прощены.",
    "Редко даю сдачи, даже если кто-нибудь ударит меня.",
    "Обижаюсь, когда иногда получается не по-моему.",
    "Иногда люди раздражают меня своим присутствием.",
    "Нет людей, которых бы я по-настоящему ненавидел.",
    "Мой принцип: «Никогда не доверять чужакам».",
    "Если кто-то раздражает меня, готов сказать ему все, что о нем думаю.",
    "Делаю много такого, о чем впоследствии сожалею.",
    "Если разозлюсь, могу ударить кого-нибудь.",
    "С десяти лет у меня не было вспышек гнева.",
    "Часто чувствую себя, как пороховая бочка, готовая взорваться.",
    "Если бы знали, что я чувствую, меня бы считали человеком, с которым нелегко ладить.",
    "Всегда думаю о том, какие тайные причины заставляют людей делать что-нибудь приятное для меня.",
    "Когда кричат на меня, кричу в ответ.",
    "Неудачи огорчают меня.",
    "Дерусь не реже и не чаще других.",
    "Могу вспомнить случаи, когда была настолько зла, что хватал первую попавшуюся под руку вещь и ломал ее.",
    "Иногда чувствую, что готов первым начать драку.",
    "Иногда чувствую, что жизнь со мной поступает несправедливо.",
    "Раньше думал, что большинство людей говорит правду, но теперь этому не верю.",
    "Ругаюсь только от злости.",
    "Когда поступаю неправильно, меня мучает совесть.",
    "Если для защиты своих прав нужно применить физическую силу, я применяю ее.",
    "Иногда выражаю свой гнев тем, что стучу по столу.",
    "Бываю, грубоват по отношению к людям, которые мне не нравятся.",
    "У меня нет врагов, которые хотели бы мне навредить.",
    "Не умею поставить человека на место, даже если он этого заслуживает.",
    "Часто думаю, что живу неправильно.",
    "Знаю людей, которые способны довести меня до драки.",
    "Не огорчаюсь из-за мелочей.",
    "Мне редко приходит в голову мысль о том, что люди пытаются разозлить или оскорбить меня.",
    "Часто просто угрожаю людям, не собираясь приводить угрозы в исполнение.",
    "В последнее время я стал занудой.",
    "В споре часто повышаю голос.",
    "Стараюсь скрывать плохое отношение к людям.",
    "Лучше соглашусь с чем-либо, чем стану спорить.", // III.	/Тест Басса -Дарки
    "Мне тяжело видеть, как кто-то страдает.", // Темный фактор личности D70
    "Лучше быть скромным и честным, чем высокопоставленным и бесчестным.",
    "Умный человек всегда знает, что, когда, как и кому сказать, чтобы наказать обидчика.",
    "Большинство людей в основном хорошие и добрые.",
    "Я не против того, чтобы в центре внимания был не только я, но и другие люди.",
    "Расплата должна быть быстрой и безжалостной.",
    "Если бы я был на «Титанике», я не больше, чем другие, заслуживал бы оказаться на первой спасательной шлюпке.",
    "Когда я раздражаюсь, мне становится легче, если я третирую других людей.",
    "Нельзя распускать слухи даже для защиты своих близких.",
    "Если бы мой сосед жаловался, что у меня слишком громко играет музыка, я бы сделал потише, даже если бы меня это раздражало.",
    "Единственное, что имеет значение, это мое собственное удовольствие.",
    "Если короткий путь к успеху противозаконен, то глупо его использовать.",
    "Неважно, сколько у меня есть, я всегда хочу большего.",
    "Я считаю, что для сохранения конкурентных преимуществ перед другими, необходимо лгать.",
    "В принципе, все люди ценны в равной степени.",
    "Я скажу всё, что угодно, чтобы получить то, что хочу.",
    "Я бы чувствовал себя плохо, если бы кого-нибудь обидел.",
    "Если бы у меня была возможность, я бы с удовольствием заплатил небольшую сумму, чтобы увидеть, как одноклассник, которого я не люблю, провалит свой выпускной экзамен.",
    "Совершать добрые дела не имеет смысла, это лишь делает людей бедными и ленивыми.",
    "Никогда не говорите никому о реальной причине, по которой вы что-то сделали, если только это не будет вам полезно.",
    "Ради собственной выгоды человек должен использовать любые доступные ему средства, заботясь, конечно, о том, чтобы об этом не узнали.",
    "Вообще-то, я немного жадный.",
    "Я не стремлюсь к контролю над другими людьми.",
    "Люди, которые достают меня, всегда жалеют об этом.",
    "Не всегда всё может быть по-моему.",
    "Мне бы хотелось поиздеваться над другими просто для удовольствия.",
    "Недопустимо получать очки за чьи-то чужие идеи.",
    "Я не слишком сочувствую другим людям и их проблемам.",
    "Было время, когда я был готов пойти на некоторые потери для того, чтобы наказать того, кто этого заслуживает.",
    "Почему я должен заботиться о других людях, когда никто обо мне не заботится?",
    "Даже если бы шанс попасться был небольшой, я бы не стал жульничать.",
    "Большинство людей – в какой-то степени неудачники.",
    "Мне не очень нравится манипулировать чужими чувствами.",
    "Я не могу себе представить, как подлость может хоть как-то нравиться и даже щекотать нервы.",
    "Я готов быть волонтером, который помогает нуждающимся.",
    "Чтобы зарабатывать деньги, больше не существует правильных и неправильных способов. Только легкие и трудные пути.",
    "В мести мало утешения.",
    "Большинство людей заслуживают уважения.",
    "Если в центре событий не я, а другой человек, мне трудно это вынести.",
    "Я стараюсь не навредить другим, преследуя свои цели.",
    "Я не заслуживаю большего, чем другие.",
    "Я ненавижу видеть, как люди страдают.",
    "Если в выставленном вам счете сделана ошибка в вашу пользу, вы не должны об этом говорить, потому что это не ваша вина.",
    "Я стараюсь заботиться прежде всего о себе, даже если это создает проблемы другим людям.",
    "Если бы я выступал против избрания кого-нибудь, то был бы рад, если бы этот человек потерпел неудачу, даже если бы это было во вред тем, с кем я вместе живу и работаю.",
    "Добрые дела доставляют радость.",
    "Человек должен подчиняться закону, даже если это мешает достижению его целей.",
    "Я хотел бы заставить некоторых людей страдать, даже если бы это означало, что я буду гореть за это в аду.",
    "Сколько бы у меня ни было, мне всегда этого мало.",
    "Разумно отслеживать информацию, которую впоследствии можно будет использовать против других.",
    "Я не получаю особого удовольствия от провала соперника.",
    "Я не хочу, чтобы люди боялись меня или моих поступков.",
    "Тот, кто обижает меня, не может рассчитывать на мою симпатию.",
    "Я стремлюсь не унижать других людей.",
    "Люди, с которыми плохо обращаются, как правило, сами сделали что-то, что вызвало такое отношение.",
    "Я готов ограничить свои стремления, чтобы не создавать проблем другим.",
    "Я готов принять удар, лишь бы тот, кто мне не нравится, получил два.",
    "Истории о честности и доброте служат лишь тому, чтобы сбить людей с толку и одурачить их.",
    "Трудно продвигаться вперед, не нарушая каких-то правил.",
    "Месть слаще, когда ее долго готовишь.",
    "Во многих случаях надо уметь вовремя остановиться, понимая, что того, что есть, уже достаточно.",
    "Убедитесь, что ваши планы приносят пользу вам, а не другим.",
    "Я не стремлюсь к власти.",
    "Успех основан на выживании наиболее приспособленных; меня не волнуют проигравшие.",
    "Я склонен прощать то зло, от которого пострадал.",
    "Я раскаивался бы, если бы когда-то издевался над другими людьми.",
    "Это нормально – плохо обращаться с тем, кто ведет себя, как негодяй.",
    "Мне жаль, если то, что я делаю, расстраивает людей.",
    "Порой стоит немного пострадать, чтоб помочь другим.",
    "Я не начинаю думать о себе лучше, заставляя других думать о самих себе хуже.", // /Темный фактор личности D70
    "Позволяю себе поделиться чувством с другом.",
    "Стараюсь все сделать так, чтобы иметь возможность наилучшим образом решить проблему.",
    "Осуществляю поиск всех возможных решений, прежде чем что-то предпринять.",
    "Пытаюсь отвлечься от проблемы.",
    "Принимаю сочувствие и понимание от кого-либо.",
    "Делаю все возможное, чтобы не дать окружающим увидеть, что мои дела плохи.",
    "Обсуждаю ситуацию с людьми, так как обсуждение помогает мне чувствовать себя лучше.",
    "Ставлю для себя ряд целей, позволяющих постепенно справляться с ситуацией.",
    "Очень тщательно взвешиваю возможности выбора.",
    "Мечтаю, фантазирую о лучших временах.",
    "Пытаюсь различными способами решать проблему, пока не найду подходящий.",
    "Доверяю свои страхи родственнику или другу.",
    "Больше времени, чем обычно, провожу один.",
    "Рассказываю другим людям о ситуации, так как только ее обсуждение помогает мне прийти к ее разрешению.",
    "Думаю о том, что нужно сделать, чтобы исправить положение.",
    "Сосредотачиваюсь полностью на решении проблемы.",
    "Обдумываю про себя план действий.",
    "Смотрю телевизор дольше, чем обычно.",
    "Иду к кому-нибудь (другу или специалисту), чтобы он помог мне чувствовать себя лучше.",
    "Стою твердо и борюсь за то, что мне нужно в этой ситуации.",
    "Избегаю общения с людьми.",
    "Переключаюсь на хобби или занимаюсь спортом, чтобы избежать проблем.",
    "Иду к другу за советом – как исправить ситуацию.",
    "Иду к другу, чтобы он помог мне лучше почувствовать проблему.",
    "Принимаю сочувствие, взаимопонимание друзей.",
    "Сплю больше обычного.",
    "Фантазирую о том, что все могло бы быть иначе.",
    "Представляю себя героем книги или кино.",
    "Пытаюсь решить проблему.",
    "Хочу, чтобы люди оставили меня одного.",
    "Принимаю помощь от друзей или родственников.",
    "Ищу успокоения у тех, кто знает меня лучше.",
    "Пытаюсь тщательно планировать свои действия, а не действовать импульсивно под влиянием внешнего побуждения.",
    "Я чувствую себя эмоционально опустошенным(ой).",
    "К концу рабочего дня я чувствую себя, как выжатый лимон.",
    "Я чувствую себя усталым(ой), когда встаю утром  и должен(на) идти на работу.",
    "Я хорошо понимаю, что чувствуют мои пациенты и коллеги, и использую это для более успешного лечения.",
    "Я общаюсь с моими   пациентами только формально, без лишних эмоций, и стремлюсь  свести время общение с ними до минимума.",
    "Я чувствую себя энергичным(ой) и эмоционально приподнятым(ой).",
    "Я умею находить правильное решение в конфликтных ситуациях с больными и их родственниками",
    "Я чувствую угнетенность и апатию.",
    "Я могу позитивно влиять на самочувствие и настроение  больных (пациентов).",
    "В последнее время я стал(а) более черствым(ой) (бесчувственным) по отношению к больным.",
    "Как правило, окружающие меня люди слишком много требуют от меня. Они скорее утомляют, чем радуют меня.",
    "У меня много планов на будущее, и я верю в их осуществление.",
    "Я испытываю все больше жизненных разочарований.",
    "Я чувствую равнодушие и потерю интереса  ко многому, что радовало меня раньше.",
    "Бывает, мне действительно безразлично то, что происходит с некоторыми моими больными.",
    "Мне хочется уединиться и отдохнуть от всего и от всех.",
    "Я легко могу создать атмосферу доброжелательности и оптимизма в отношениях  с моими коллегами и в отношениях с моими больными.",
    "Я легко общаюсь с больными и их родственниками  независимо от их социального статуса и характера..",
    "Я легко общаюсь с больными и их родственниками  независимо от их социального статуса и характера..",
    "Я чувствую себя на пределе своих возможностей.",
    "Я многого еще смогу достичь в своей жизни.",
    "Больные, как правило, - неблагодарные люди.",
  ];

  useEffect(() => {
    // Define the endpoint URL
    const endpointUrl =
      "https://admin.mame-darom.ru/wp-json/custom/v1/endpoint";

    // Fetch data from the endpoint
    fetch(endpointUrl)
      .then((response) => response.json())
      .then((data) => {
        // Map and extract the "processed_answers" data from the response
        const processedAnswers = data.map((item) => {
          const content = JSON.parse(item.content);
          return content.processed_answers;
        });

        // Now, you have an array of "processed_answers" data
        console.log(processedAnswers);
        setSubmitedAnswersFromServer(processedAnswers);
        // You can perform further operations with the data here
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const transformValues = (val, index, array) => {
    const length = array.length;
    if (index >= length - 35) {
      switch (val) {
        case "1":
          return "Полностью согласен";
        case "2":
          return "Согласен";
        case "3":
          return "Не согласен";
        default:
          return val;
      }
    } else if (index >= 91 && index <= 160) {
      switch (val) {
        case "1":
          return "Абсолютно не согласен";
        case "2":
          return "Скорее не согласен";
        case "3":
          return "Сложно сказать";
        case "4":
          return "Скорее согласен";
        case "5":
          return "Совершенно согласен";
        default:
          return val;
      }
    } else if (index >= 195 && index <= 216) {
      switch (val) {
        case "0":
          return "Никогда";
        case "1":
          return "Очень редко";
        case "2":
          return "Редко";
        case "3":
          return "Иногда";
        case "4":
          return "Часто";
        case "5":
          return "Очень часто";
        case "6":
          return "Всегда";
        default:
          return val;
      }
    }
    return val;
  };

  const exportToExcel = () => {
    const data = [
      headers, // Header row
      ...submitedAnswersFromServer.map((answer) =>
        answer.map((val, num, arr) => transformValues(val, num, arr)),
      ),
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "your_file_name.xlsx");
  };

  return (
    <>
      <div>
        {isLoggedIn ? (
          <>
            <div style={{ position: "fixed", zIndex: 1111 }}>
              <button onClick={exportToExcel}>Скачать в Excel</button>
            </div>
            <Table>
              <WrapHead style={{ position: "absolute" }}>
                {headers.map((header, index) => (
                  <HeadInner key={index}>
                    <WrapSpan>{header}</WrapSpan>
                  </HeadInner>
                ))}
              </WrapHead>

              {submitedAnswersFromServer?.map((answer, index) => {
                return (
                  <WrapAnswers>
                    {answer.map((val, num, arr) => (
                      <AnswersItem key={num}>
                        {transformValues(val, num, arr)}
                      </AnswersItem>
                    ))}
                  </WrapAnswers>
                );
              })}
            </Table>
          </>
        ) : (
          <WrapFrom>
            <input
              type="text"
              placeholder="логин"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <input
              type="password"
              placeholder="пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Вход</button>
            {/*<pre>{JSON.stringify(responseData, null, 2)}</pre>*/}
          </WrapFrom>
        )}
      </div>
    </>
  );
};

//Accessing Posts:
//
// To fetch all posts: "https://admin.mame-darom.ru/wp-json/wp/v2/posts"
// To fetch a specific post by ID: "https://admin.mame-darom.ru/wp-json/wp/v2/posts/{post_id}"
// Accessing Pages:
//
// To fetch all pages: "https://admin.mame-darom.ru/wp-json/wp/v2/pages"
// To fetch a specific page by ID: "https://admin.mame-darom.ru/wp-json/wp/v2/pages/{page_id}"
// Accessing Custom Post Types:
//
// Replace "post_type" with the name of your custom post type.
// To fetch all custom posts: "https://admin.mame-darom.ru/wp-json/wp/v2/{post_type}"
// To fetch a specific custom post by ID: "https://admin.mame-darom.ru/wp-json/wp/v2/{post_type}/{post_id}"
// Accessing Custom Endpoints:
//
// If you've registered custom endpoints, you can access them using the base URL you provided during registration.
// Example: "https://admin.mame-darom.ru/wp-json/custom/v1/your-custom-endpoint"
// Authentication:
//
// Depending on your site's authentication settings, you may need to include authentication headers (such as cookies or tokens) in your requests if the endpoints require authentication.
