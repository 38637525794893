import React, { Fragment, useState, useEffect } from "react";
import {
  QuizContainer,
  QuestionContainer,
  RadioLabel,
  Select,
  ButtonContainer,
  Button,
  WrapPositionFixedDix,
} from "./QuizStyled";
import { Liusher } from "./liusherData";
import RotatedTable from "./RotatedTable";
import DeleteDataButton from "./DeleteDataButton";
import Popup from "./Popup";

const questions = [
  "",
  "", // Tip: Введите ваш возраст числом
  "", // Tip: Введите ваш стаж работы числом
  "",
  "", // Tip: Укажите длительность
  "Выберите вариант",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "Прочитайте утверждения, примеряйте, насколько они соответствуют вашему стилю поведения, вашему образу жизни, и отвечайте одним из четырех возможных ответов:",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  'Пожалуйста, прочитайте каждое утверждение и решите, насколько вы согласны или не согласны с ним. Обратите внимание, что на эти утверждения нет "правильных" или "неправильных" ответов. Пожалуйста, ответьте на каждое утверждение, даже если вы не полностью уверены в своем ответе. Если не указано иное, вопросы касаются вашего поведения (по отношению к другим) в целом.',
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "В тесте представлено несколько возможных путей преодоления проблем, неприятностей. Ознакомившись с утверждениями, вы сможете определить, какие из предложенных вариантов обычно вами используются.\n" +
    "Попытайтесь вспомнить об одной из серьезных проблем, с которой вы столкнулись за последний год и которая заставила вас изрядно беспокоиться. Опишите эту проблему в нескольких словах.\n" +
    "Теперь, читая приведенные ниже утверждения, выберите один из трех наиболее приемлемых вариантов ответов для каждого утверждения.\n", // Индикатор копинг-стратегий , CSI
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "", // / Индикатор копинг-стратегий , CSI
  "Ниже приведен список утверждений, характеризующий чувства и переживания, которые могут возникать в связи с вашей работой. Прочитайте пожалуйста, каждое из утверждений и оцените, насколько оно соответствует Вашим личным переживаниям. Если у Вас они не возникают никогда, то подчеркните оценку «0». Если такие переживания возникают, оцените, как часто это случается; «1» - очень редко; «2» - редко; «3» — иногда; «4» - часто; «5» - очень часто; «6» -всегда.0",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

const questionTips = [
  "Укажите Ваш пол",
  "Введите ваш возраст(числом)",
  "Укажите Ваш стаж работы врачом акушером-гинекологом",
  "Входят ли в ваши обязанности вопросы касаемо абортов?",
  "Если Вы занимаетесь вопросами абортов, то укажите как долго?",
  "Проведение абортов для Вас ",
  "Приходится ли вам осуществлять стоматологические услуги?",
  "Стаж работы стоматологом, как долго?",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Ваша задача – указать, какой цвет вам нравится больше других в данный момент времени:",
  "Временами не могу справиться с желанием навредить кому-либо.", // III.	Тест Басса -Дарки
  "Иногда могу посплетничать о людях, которых не люблю.",
  "Легко раздражаюсь, но легко и успокаиваюсь.",
  "Если меня не попросят по-хорошему, просьбу не выполню.",
  "Не всегда получаю то, что мне положено.",
  "Знаю, что люди говорят обо мне за моей спиной.",
  "Если не одобряю поступки других людей, даю им это почувствовать.",
  "Если случается обмануть кого-либо, испытываю угрызения совести.",
  "Мне кажется, что я не способен ударить человека.",
  "Никогда не раздражаюсь настолько, чтобы разбрасывать вещи.",
  "Всегда снисходителен к чужим недостаткам.",
  "Когда установленное правило не нравится мне, хочется нарушить его.",
  "Другие почти всегда умеют использовать благоприятные обстоятельства.",
  "Меня настораживают люди, которые относятся ко мне более дружелюбно, чем я этого ожидаю.",
  "Часто бываю, не согласен с людьми.",
  "Иногда на ум приходят мысли, которых я стыжусь.",
  "Если кто-нибудь ударит меня, я не отвечу ему тем же.",
  "В раздражении хлопаю дверьми.",
  "Я более раздражителен, чем кажется со стороны.",
  "Если кто-то корчит из себя начальника, я поступаю ему наперекор.",
  "Меня немного огорчает моя судьба.",
  "Думаю, что многие люди не любят меня.",
  "Не могу удержаться от спора, если люди не согласны со мной.",
  "Увиливающие от работы должны испытывать чувство вины.",
  "Кто оскорбляет меня или мою семью, напрашивается на драку.",
  "Я не способен на грубые шутки.",
  "Меня охватывает ярость, когда надо мной насмехаются.",
  "Когда люди строят из себя начальников, я делаю все, чтобы они не зазнавались.",
  "Почти каждую неделю вижу кого-нибудь из тех, кто мне не нравится.",
  "Довольно многие завидуют мне.",
  "Требую, чтобы люди уважали мои права.",
  "Меня огорчает, что я мало делаю для своих родителей.",
  "Люди, которые постоянно изводят вас, стоят того, чтобы их щелкнули по носу.",
  "От злости иногда бываю мрачным.",
  "Если ко мне относятся хуже, чем я того заслуживаю, я не огорчаюсь.",
  "Если кто-то пытается вывести меня из себя, я не обращаю на него внимания.",
  "Хотя я и не показываю этого, иногда меня гложет зависть.",
  "Иногда мне кажется, что надо мной смеются.",
  "Даже если злюсь, не прибегаю к сильным выражениям.",
  "Хочется, чтобы мои грехи были прощены.",
  "Редко даю сдачи, даже если кто-нибудь ударит меня.",
  "Обижаюсь, когда иногда получается не по-моему.",
  "Иногда люди раздражают меня своим присутствием.",
  "Нет людей, которых бы я по-настоящему ненавидел.",
  "Мой принцип: «Никогда не доверять чужакам».",
  "Если кто-то раздражает меня, готов сказать ему все, что о нем думаю.",
  "Делаю много такого, о чем впоследствии сожалею.",
  "Если разозлюсь, могу ударить кого-нибудь.",
  "С десяти лет у меня не было вспышек гнева.",
  "Часто чувствую себя, как пороховая бочка, готовая взорваться.",
  "Если бы знали, что я чувствую, меня бы считали человеком, с которым нелегко ладить.",
  "Всегда думаю о том, какие тайные причины заставляют людей делать что-нибудь приятное для меня.",
  "Когда кричат на меня, кричу в ответ.",
  "Неудачи огорчают меня.",
  "Дерусь не реже и не чаще других.",
  "Могу вспомнить случаи, когда была настолько зла, что хватал первую попавшуюся под руку вещь и ломал ее.",
  "Иногда чувствую, что готов первым начать драку.",
  "Иногда чувствую, что жизнь со мной поступает несправедливо.",
  "Раньше думал, что большинство людей говорит правду, но теперь этому не верю.",
  "Ругаюсь только от злости.",
  "Когда поступаю неправильно, меня мучает совесть.",
  "Если для защиты своих прав нужно применить физическую силу, я применяю ее.",
  "Иногда выражаю свой гнев тем, что стучу по столу.",
  "Бываю, грубоват по отношению к людям, которые мне не нравятся.",
  "У меня нет врагов, которые хотели бы мне навредить.",
  "Не умею поставить человека на место, даже если он этого заслуживает.",
  "Часто думаю, что живу неправильно.",
  "Знаю людей, которые способны довести меня до драки.",
  "Не огорчаюсь из-за мелочей.",
  "Мне редко приходит в голову мысль о том, что люди пытаются разозлить или оскорбить меня.",
  "Часто просто угрожаю людям, не собираясь приводить угрозы в исполнение.",
  "В последнее время я стал занудой.",
  "В споре часто повышаю голос.",
  "Стараюсь скрывать плохое отношение к людям.",
  "Лучше соглашусь с чем-либо, чем стану спорить.", // III.	/Тест Басса -Дарки
  "Мне тяжело видеть, как кто-то страдает.", // Темный фактор личности D70
  "Лучше быть скромным и честным, чем высокопоставленным и бесчестным.",
  "Умный человек всегда знает, что, когда, как и кому сказать, чтобы наказать обидчика.",
  "Большинство людей в основном хорошие и добрые.",
  "Я не против того, чтобы в центре внимания был не только я, но и другие люди.",
  "Расплата должна быть быстрой и безжалостной.",
  "Если бы я был на «Титанике», я не больше, чем другие, заслуживал бы оказаться на первой спасательной шлюпке.",
  "Когда я раздражаюсь, мне становится легче, если я третирую других людей.",
  "Нельзя распускать слухи даже для защиты своих близких.",
  "Если бы мой сосед жаловался, что у меня слишком громко играет музыка, я бы сделал потише, даже если бы меня это раздражало.",
  "Единственное, что имеет значение, это мое собственное удовольствие.",
  "Если короткий путь к успеху противозаконен, то глупо его использовать.",
  "Неважно, сколько у меня есть, я всегда хочу большего.",
  "Я считаю, что для сохранения конкурентных преимуществ перед другими, необходимо лгать.",
  "В принципе, все люди ценны в равной степени.",
  "Я скажу всё, что угодно, чтобы получить то, что хочу.",
  "Я бы чувствовал себя плохо, если бы кого-нибудь обидел.",
  "Если бы у меня была возможность, я бы с удовольствием заплатил небольшую сумму, чтобы увидеть, как одноклассник, которого я не люблю, провалит свой выпускной экзамен.",
  "Совершать добрые дела не имеет смысла, это лишь делает людей бедными и ленивыми.",
  "Никогда не говорите никому о реальной причине, по которой вы что-то сделали, если только это не будет вам полезно.",
  "Ради собственной выгоды человек должен использовать любые доступные ему средства, заботясь, конечно, о том, чтобы об этом не узнали.",
  "Вообще-то, я немного жадный.",
  "Я не стремлюсь к контролю над другими людьми.",
  "Люди, которые достают меня, всегда жалеют об этом.",
  "Не всегда всё может быть по-моему.",
  "Мне бы хотелось поиздеваться над другими просто для удовольствия.",
  "Недопустимо получать очки за чьи-то чужие идеи.",
  "Я не слишком сочувствую другим людям и их проблемам.",
  "Было время, когда я был готов пойти на некоторые потери для того, чтобы наказать того, кто этого заслуживает.",
  "Почему я должен заботиться о других людях, когда никто обо мне не заботится?",
  "Даже если бы шанс попасться был небольшой, я бы не стал жульничать.",
  "Большинство людей – в какой-то степени неудачники.",
  "Мне не очень нравится манипулировать чужими чувствами.",
  "Я не могу себе представить, как подлость может хоть как-то нравиться и даже щекотать нервы.",
  "Я готов быть волонтером, который помогает нуждающимся.",
  "Чтобы зарабатывать деньги, больше не существует правильных и неправильных способов. Только легкие и трудные пути.",
  "В мести мало утешения.",
  "Большинство людей заслуживают уважения.",
  "Если в центре событий не я, а другой человек, мне трудно это вынести.",
  "Я стараюсь не навредить другим, преследуя свои цели.",
  "Я не заслуживаю большего, чем другие.",
  "Я ненавижу видеть, как люди страдают.",
  "Если в выставленном вам счете сделана ошибка в вашу пользу, вы не должны об этом говорить, потому что это не ваша вина.",
  "Я стараюсь заботиться прежде всего о себе, даже если это создает проблемы другим людям.",
  "Если бы я выступал против избрания кого-нибудь, то был бы рад, если бы этот человек потерпел неудачу, даже если бы это было во вред тем, с кем я вместе живу и работаю.",
  "Добрые дела доставляют радость.",
  "Человек должен подчиняться закону, даже если это мешает достижению его целей.",
  "Я хотел бы заставить некоторых людей страдать, даже если бы это означало, что я буду гореть за это в аду.",
  "Сколько бы у меня ни было, мне всегда этого мало.",
  "Разумно отслеживать информацию, которую впоследствии можно будет использовать против других.",
  "Я не получаю особого удовольствия от провала соперника.",
  "Я не хочу, чтобы люди боялись меня или моих поступков.",
  "Тот, кто обижает меня, не может рассчитывать на мою симпатию.",
  "Я стремлюсь не унижать других людей.",
  "Люди, с которыми плохо обращаются, как правило, сами сделали что-то, что вызвало такое отношение.",
  "Я готов ограничить свои стремления, чтобы не создавать проблем другим.",
  "Я готов принять удар, лишь бы тот, кто мне не нравится, получил два.",
  "Истории о честности и доброте служат лишь тому, чтобы сбить людей с толку и одурачить их.",
  "Трудно продвигаться вперед, не нарушая каких-то правил.",
  "Месть слаще, когда ее долго готовишь.",
  "Во многих случаях надо уметь вовремя остановиться, понимая, что того, что есть, уже достаточно.",
  "Убедитесь, что ваши планы приносят пользу вам, а не другим.",
  "Я не стремлюсь к власти.",
  "Успех основан на выживании наиболее приспособленных; меня не волнуют проигравшие.",
  "Я склонен прощать то зло, от которого пострадал.",
  "Я раскаивался бы, если бы когда-то издевался над другими людьми.",
  "Это нормально – плохо обращаться с тем, кто ведет себя, как негодяй.",
  "Мне жаль, если то, что я делаю, расстраивает людей.",
  "Порой стоит немного пострадать, чтоб помочь другим.",
  "Я не начинаю думать о себе лучше, заставляя других думать о самих себе хуже.", // /Темный фактор личности D70
  "Позволяю себе поделиться чувством с другом.",
  "Стараюсь все сделать так, чтобы иметь возможность наилучшим образом решить проблему.",
  "Осуществляю поиск всех возможных решений, прежде чем что-то предпринять.",
  "Пытаюсь отвлечься от проблемы.",
  "Принимаю сочувствие и понимание от кого-либо.",
  "Делаю все возможное, чтобы не дать окружающим увидеть, что мои дела плохи.",
  "Обсуждаю ситуацию с людьми, так как обсуждение помогает мне чувствовать себя лучше.",
  "Ставлю для себя ряд целей, позволяющих постепенно справляться с ситуацией.",
  "Очень тщательно взвешиваю возможности выбора.",
  "Мечтаю, фантазирую о лучших временах.",
  "Пытаюсь различными способами решать проблему, пока не найду подходящий.",
  "Доверяю свои страхи родственнику или другу.",
  "Больше времени, чем обычно, провожу один.",
  "Рассказываю другим людям о ситуации, так как только ее обсуждение помогает мне прийти к ее разрешению.",
  "Думаю о том, что нужно сделать, чтобы исправить положение.",
  "Сосредотачиваюсь полностью на решении проблемы.",
  "Обдумываю про себя план действий.",
  "Смотрю телевизор дольше, чем обычно.",
  "Иду к кому-нибудь (другу или специалисту), чтобы он помог мне чувствовать себя лучше.",
  "Стою твердо и борюсь за то, что мне нужно в этой ситуации.",
  "Избегаю общения с людьми.",
  "Переключаюсь на хобби или занимаюсь спортом, чтобы избежать проблем.",
  "Иду к другу за советом – как исправить ситуацию.",
  "Иду к другу, чтобы он помог мне лучше почувствовать проблему.",
  "Принимаю сочувствие, взаимопонимание друзей.",
  "Сплю больше обычного.",
  "Фантазирую о том, что все могло бы быть иначе.",
  "Представляю себя героем книги или кино.",
  "Пытаюсь решить проблему.",
  "Хочу, чтобы люди оставили меня одного.",
  "Принимаю помощь от друзей или родственников.",
  "Ищу успокоения у тех, кто знает меня лучше.",
  "Пытаюсь тщательно планировать свои действия, а не действовать импульсивно под влиянием внешнего побуждения.",
  "Я чувствую себя эмоционально опустошенным(ой).",
  "К концу рабочего дня я чувствую себя, как выжатый лимон.",
  "Я чувствую себя усталым(ой), когда встаю утром  и должен(на) идти на работу.",
  "Я хорошо понимаю, что чувствуют мои пациенты и коллеги, и использую это для более успешного лечения.",
  "Я общаюсь с моими   пациентами только формально, без лишних эмоций, и стремлюсь  свести время общение с ними до минимума.",
  "Я чувствую себя энергичным(ой) и эмоционально приподнятым(ой).",
  "Я умею находить правильное решение в конфликтных ситуациях с больными и их родственниками",
  "Я чувствую угнетенность и апатию.",
  "Я могу позитивно влиять на самочувствие и настроение  больных (пациентов).",
  "В последнее время я стал(а) более черствым(ой) (бесчувственным) по отношению к больным.",
  "Как правило, окружающие меня люди слишком много требуют от меня. Они скорее утомляют, чем радуют меня.",
  "У меня много планов на будущее, и я верю в их осуществление.",
  "Я испытываю все больше жизненных разочарований.",
  "Я чувствую равнодушие и потерю интереса  ко многому, что радовало меня раньше.",
  "Бывает, мне действительно безразлично то, что происходит с некоторыми моими больными.",
  "Мне хочется уединиться и отдохнуть от всего и от всех.",
  "Я легко могу создать атмосферу доброжелательности и оптимизма в отношениях  с моими коллегами и в отношениях с моими больными.",
  "Я легко общаюсь с больными и их родственниками  независимо от их социального статуса и характера..",
  "Я легко общаюсь с больными и их родственниками  независимо от их социального статуса и характера..",
  "Я чувствую себя на пределе своих возможностей.",
  "Я многого еще смогу достичь в своей жизни.",
  "Больные, как правило, - неблагодарные люди.",
];
let selectedColors = [];
function Home() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(questions.length).fill(""));
  const [showFinishButton, setShowFinishButton] = useState(false);
  const [coefficients, setCoefficients] = useState({});
  const [message, setMessage] = useState(false);

  const [processedData, setProcessedData] = useState([]);

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      if (answers[currentQuestion] === "") {
        alert("Прежде чем продолжить, пожалуйста, ответьте на текущий вопрос.");
      } else {
        setCurrentQuestion(currentQuestion + 1);
      }
    }
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        handleNext();
      }
    };

    // Add event listener to the input fields or a container element that wraps them
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleNext]);

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleInputChange = (e) => {
    const newAnswers = [...answers];
    const inputValue = e.target.value;

    if (
      (currentQuestion === 1 || currentQuestion === 2) &&
      !/^\d+$/.test(inputValue)
    ) {
      // Only allow numeric input for questions 1 and 2
      return;
    }

    newAnswers[currentQuestion] = inputValue;
    setAnswers(newAnswers);
    checkAnswers(newAnswers);
  };

  const checkAnswers = (newAnswers) => {
    // Check if all questions are answered
    if (newAnswers.every((answer) => answer !== "")) {
      setShowFinishButton(true);
    } else {
      setShowFinishButton(false);
    }
  };
  // https://admin.mame-darom.ru/wp-json/custom/v1/create-post
  // https://admin.mame-darom.ru/wp-json/wp/v2/posts

  const saveData = async () => {
    try {
      const saveDataEndpoint =
        "https://admin.mame-darom.ru/wp-json/custom/v1/endpoint";

      const response = await fetch(saveDataEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: "Your Post Title",
          content: JSON.stringify({
            processed_answers: answers,
            submitted_answers: processedData,
          }),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Data saved successfully. Post ID:", data.id);
        setAnswers([]); // Reset the answers data
        setProcessedData([]);
        setMessage(true);
      } else {
        console.error("Failed to save data:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = () => {
    console.log("Submitted Answers:", JSON.stringify(answers));
    console.log("Processed answers:", JSON.stringify(processedData));
    saveData()
      .then((e) => {
        console.log("savedataRunned");
      })
      .catch((e) => {
        console.log("Error in save");
      });
  };

  const colorArray = [
    "grey",
    "blue",
    "green",
    "violet",
    "black",
    "brown",
    "yellow",
    "red",
  ];

  // console.log("answers", answers);

  // Calculate progress based on the number of empty items
  const calculateProgress = () => {
    const emptyItems = answers.filter((item) => item === "").length;
    const totalItems = answers.length;
    const progress = ((totalItems - emptyItems) / totalItems) * 100;
    return progress.toFixed(2); // Limit decimal places if needed
  };

  const colors = [
    "grey",
    "blue",
    "green",
    "violet",
    "black",
    "brown",
    "yellow",
    "red",
  ];

  const rangeMin = 7;
  const rangeMax = 267;
  const numColors = colors.length;

  const range = rangeMax - rangeMin;
  const step = range / numColors;

  let currentValue = rangeMin;

  const liusherCoefficients = {};

  for (const color of colors) {
    liusherCoefficients[color] = currentValue;
    currentValue += step;
  }

  console.log("processedData", processedData);

  return (
    <>
      <Popup />
      {message && (
        <>
          <WrapPositionFixedDix>
            <h2>Спасибо за потраченное время и ответы!</h2>
          </WrapPositionFixedDix>
        </>
      )}
      <QuizContainer>
        <h1>Батарея методик для исследования профвыгорания врачей</h1>
        <DeleteDataButton />
        <h2 style={{ display: "none" }}>
          {currentQuestion >= 8 && currentQuestion <= 15
            ? "II.Люшер"
            : currentQuestion > 15 && currentQuestion < 91
              ? "III.Тест Басса-Дарки"
              : currentQuestion > 90 && currentQuestion < 161
                ? "IV.Темный фактор личности D70"
                : currentQuestion > 160
                  ? "V.Индикатор копинг-стратегий , CSI"
                  : "I. Анкета"}
        </h2>
        <QuestionContainer>
          <p>{questions[currentQuestion]}</p>
          {/* Display the tip or instruction for the current question */}
          <p>{questionTips[currentQuestion]}</p>
          {currentQuestion === 0 && (
            <div>
              <RadioLabel>
                М
                <input
                  type="radio"
                  name="gender"
                  value="М"
                  onChange={handleInputChange}
                />
              </RadioLabel>
              <RadioLabel>
                Ж
                <input
                  type="radio"
                  name="gender"
                  value="Ж"
                  onChange={handleInputChange}
                />
              </RadioLabel>
            </div>
          )}
          {currentQuestion === 1 && (
            <input
              type="text"
              value={answers[currentQuestion]}
              onChange={handleInputChange}
            />
          )}
          {currentQuestion === 2 && (
            <input
              type="text"
              value={answers[currentQuestion]}
              onChange={handleInputChange}
            />
          )}
          {currentQuestion === 3 && (
            <div>
              <RadioLabel>
                Да
                <input
                  type="radio"
                  name="abortion"
                  value="Да"
                  onChange={handleInputChange}
                />
              </RadioLabel>
              <RadioLabel>
                Нет
                <input
                  type="radio"
                  name="abortion"
                  value="Нет"
                  onChange={handleInputChange}
                />
              </RadioLabel>
            </div>
          )}
          {currentQuestion === 4 && (
            <input
              type="text"
              value={answers[currentQuestion]}
              onChange={handleInputChange}
            />
          )}
          {currentQuestion === 5 && (
            <Select
              onChange={handleInputChange}
              value={answers[currentQuestion]}
            >
              <option value="">выберите ответ</option>
              <option value="Обычная медицинская услуга в рамках профессиональных обязанностей">
                Обычная медицинская услуга в рамках профессиональных
                обязанностей
              </option>
              <option value="Минимально травмирующее здоровье женщины, правильное средство контроля нежелательной беременности">
                Минимально травмирующее здоровье женщины, правильное средство
                контроля нежелательной беременности
              </option>
              <option value="Надоевшая работа, от которой хочется избавится">
                Надоевшая работа, от которой хочется избавится
              </option>
              <option value="Не занимаюсь абортами">
                Не занимаюсь абортами
              </option>
            </Select>
          )}
          {currentQuestion === 6 && (
            <div>
              <RadioLabel>
                Да
                <input
                  type="radio"
                  name="abortion"
                  value="Да"
                  onChange={handleInputChange}
                />
              </RadioLabel>
              <RadioLabel>
                Нет
                <input
                  type="radio"
                  name="abortion"
                  value="Нет"
                  onChange={handleInputChange}
                />
              </RadioLabel>
            </div>
          )}
          {currentQuestion === 7 && (
            <input
              type="text"
              value={answers[currentQuestion]}
              onChange={handleInputChange}
            />
          )}
          {currentQuestion === 8 && (
            <>
              {colorArray.map((x, i) => (
                <div
                  className="liusherColors"
                  key={i}
                  onClick={() => {
                    selectedColors.push(x);
                    const newAnswers = [...answers];
                    newAnswers[currentQuestion] = x;
                    setAnswers(newAnswers);
                    setCurrentQuestion(currentQuestion + 1);
                  }}
                  style={{
                    background: x,
                    display: "inline-block",
                    margin: "0 10px",
                    borderRadius: "8px",
                    width: "50px",
                    height: "80px",
                  }}
                ></div>
              ))}
            </>
          )}
          {currentQuestion === 9 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    className="liusherColors"
                    key={i}
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}{" "}
          {currentQuestion === 10 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    className="liusherColors"
                    key={i}
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}
          {currentQuestion === 11 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    key={i}
                    className="liusherColors"
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}
          {currentQuestion === 12 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    key={i}
                    className="liusherColors"
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}{" "}
          {currentQuestion === 13 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    key={i}
                    className="liusherColors"
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}{" "}
          {currentQuestion === 14 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    key={i}
                    className="liusherColors"
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}{" "}
          {currentQuestion === 15 && (
            <>
              {colorArray
                .filter((item) => !selectedColors.includes(item))
                .map((x, i) => (
                  <div
                    key={i}
                    className="liusherColors"
                    onClick={() => {
                      selectedColors.push(x);
                      const newAnswers = [...answers];
                      newAnswers[currentQuestion] = x;
                      setAnswers(newAnswers);
                      setCurrentQuestion(currentQuestion + 1);
                    }}
                    style={{
                      background: x,
                      display: "inline-block",
                      margin: "0 10px",
                      borderRadius: "8px",
                      width: "50px",
                      height: "80px",
                    }}
                  ></div>
                ))}
            </>
          )}
          {currentQuestion > 15 && currentQuestion < 91 && (
            <div className="bassa-darki">
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="Да"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "Да"}
                />
                <div>Да</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="Пожалуй, да"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "Пожалуй, да"}
                />
                <div>Пожалуй, да</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="Пожалуй, нет"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "Пожалуй, нет"}
                />
                <div>Пожалуй, нет</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="Нет"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "Нет"}
                />
                <div>Нет</div>
              </RadioLabel>
            </div>
          )}
          {currentQuestion > 90 && currentQuestion < 161 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="1"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "1"}
                />
                <div>Абсолютно не согласен</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="2"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "2"}
                />
                <div>Скорее не согласен</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="3"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "3"}
                />
                <div>Сложно сказать</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="4"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "4"}
                />
                <div>Скорее согласен</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="5"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "5"}
                />
                <div>Совершенно согласен</div>
              </RadioLabel>
            </div>
          )}
          {currentQuestion >= 161 && currentQuestion < 194 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="1"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "1"}
                />
                <div>Полностью согласен</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="2"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "2"}
                />
                <div>Согласен</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="3"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "3"}
                />
                <div>Не согласен</div>
              </RadioLabel>
            </div>
          )}
          {currentQuestion >= 194 && currentQuestion <= 216 && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="0"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "0"}
                />
                <div>Никогда</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="1"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "1"}
                />
                <div>Очень редко</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="2"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "2"}
                />
                <div>Редко</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="3"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "3"}
                />
                <div>Иногда</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="4"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "4"}
                />
                <div>Часто</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="5"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "5"}
                />
                <div>Очень часто</div>
              </RadioLabel>
              <RadioLabel>
                <input
                  type="radio"
                  name={`question${currentQuestion}`}
                  value="6"
                  onChange={handleInputChange}
                  checked={answers[currentQuestion] === "6"}
                />
                <div>Всегда</div>
              </RadioLabel>
            </div>
          )}
        </QuestionContainer>

        <ButtonContainer>
          {![8, 9, 10, 11, 12, 13, 14, 15].includes(currentQuestion) && (
            <>
              {(currentQuestion > 18 || currentQuestion < 10) && (
                <Button onClick={handlePrev} disabled={currentQuestion === 0}>
                  Предудущии
                </Button>
              )}

              <Button
                onClick={handleNext}
                disabled={currentQuestion === questions.length - 1}
              >
                Следующии
              </Button>
            </>
          )}

          {showFinishButton && (
            <Button onClick={handleSubmit}>Завершить тест</Button>
          )}
        </ButtonContainer>
        <br />
        <br />
        <br />
        <span>
          {answers.filter((item) => item === "").length} вопрос из{" "}
          {answers.length}{" "}
        </span>
        <br />
        <br />
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${calculateProgress()}%` }}
          ></div>
        </div>
      </QuizContainer>
      <br />
      <br />
      <br />
      <div style={{ width: "998px", margin: "0 auto", display: "none" }}>
        {answers.map((value, i) => (
          <Fragment key={i}>
            <span>{` "` + value + `"  `}</span>
          </Fragment>
        ))}
      </div>
      <div
        style={{
          textAlign: "center",
          maxWidth: "998px",
          margin: "20px auto",
          display: "none",
        }}
      >
        <Liusher
          selectedColors={selectedColors}
          coefficients={coefficients}
          setCoefficients={setCoefficients}
        />
      </div>

      <span style={{ display: "none" }}>
        <RotatedTable
          answers={answers}
          coefficients={coefficients}
          processedData={processedData}
          setProcessedData={setProcessedData}
        />
      </span>
    </>
  );
}

export default Home;
